import React, { useRef, useState } from 'react'
import css from '../styles/Contact.module.scss';
import emailjs from '@emailjs/browser';

function Contact() {
    const formRef = useRef();
    const [done, setDone] = useState(false);

    const [input, setInput] = useState({
        user_name: "",
        user_subject: "",
        user_email: "",
        message: ""
    })

    function handleSubmit(e) {
        e.preventDefault();

        emailjs.sendForm('service_iqalbx4', 'template_humue33', formRef.current, 'user_NgEKdQ7IHycvsekyXWADT')
            .then((result) => {
                console.log(result.text);
                setDone(true);
                setInput({
                    user_name: "",
                    user_subject: "",
                    user_email: "",
                    message: ""
                })
            }, (error) => {
                console.log(error.text);
            });
    }

    function handleChange(e) {
        setInput(e.target.value)
    }

    return (
        <div className={css.contact}>
            <h1 className={css.title}>¡Hablemos!</h1>
            <h4>Si te queres comunicar conmigo simplemente completá el formulario</h4>
            <div className={css.wrapper}>
                <form ref={formRef} onSubmit={handleSubmit}>
                    <div>
                    <label for="name">Nombre</label>
                    <input type="text" placeholder="Nombre" id="name" name="user_name" required value={input.user_name} onChange={handleChange} />
                    </div>
                    <div>
                    <label>Asunto</label>
                    <input type="text" placeholder="Asunto" id="asunto" name="user_subject" required value={input.user_subject} onChange={handleChange} />
                    </div>
                    <div>
                    <label for="email">Email</label>
                    <input type="email" placeholder="Email" id="email" name="user_email" required value={input.user_email} onChange={handleChange} />
                    </div>
                    <div>
                    <label for="mensaje">Mensaje</label>
                    <textarea rows="5" placeholder="Mensaje" id="mensaje" name="message" required value={input.message} onChange={handleChange} />
                    </div>
                    <div className={css.b}>
                        <button>Enviar</button>
                    </div>
                    {done && "Mensaje enviado"}
                </form>
            </div>
        </div>
    )
}

export default Contact
