export const proyectos = [
    {
        id: 1,
        name: "APTO",
        src: require("./assets/projects/APTO.png"),
        tecnologies: "Figma, Photoshop e Illustrator",
        behance: "https://www.behance.net/gallery/176011939/APTO",
        figma: "https://www.figma.com/proto/jhkdVfM5JXKpm8MlCmCCpl/APTO?node-id=1-1116&starting-point-node-id=1%3A1116&mode=design&t=QvNtbH4s8x8RSgIq-1",
        descripcion: "Diseño UX y UI de una app que facilita la búsqueda de restaurantes con menú apto para celíacos. Para el diseño nos inspiramos en los colores que representan los granos aptos para celíacos."
    },
    {
        id: 2,
        name: "Marvel",
        src: require("./assets/projects/marvel.jpeg"),
        link: "http://renataloustalet.com.ar/marvel/",
        tecnologies: "React, Redux, SASS",
        repo: "https://github.com/renataloustalet/API-Marvel",
        descripcion: "Diseño y desarrollo de una SPA que consume datos de la API de Marvel, en la cual se encuentran entre sus funcionalidades la búsqueda por personaje y la opción de agregarlo a favoritos. Para el diseño me inspiré en los colores tipicos de Marvel."
    },
    {
        id: 3,
        name: "Countries",
        src: require("./assets/projects/countries.jpeg"),
        link: "https://renataloustalet.com.ar/countries",
        tecnologies: "React, Redux, Nodejs, SASS, Express, Sequelize y PostgreSQL",
        repo: "https://github.com/renataloustalet/PI-Countries",
        descripcion: "Diseño y desarrollo de una SPA que consume datos de una API (restcountries). Algunas de sus funcionalidades son la búsqueda por nombre, filtros y un formulario para la creación de nuevas actividades. El diseño está inspirado en planeta Tierra, por lo que utilicé los tonos de colores que se pueden encontrar en la naturaleza. Las cards de cada país son una especie de continentes, mientras que el fondo degrade simula los colores del océano.",
    },
    {
        id: 4,
        name: "Mi portfolio",
        src: require("./assets/projects/portfolio.jpeg"),
        link: "https://renataloustalet.com.ar/",
        tecnologies: "React, SASS",
        descripcion: "Diseño y desarrollo de una SPA, en la cual utilicé las tecnologías de React y SASS. El diseño está insipirado en el sistema operativo de mac 'macOS Big Sur' y en su bloc de notas."
    }
]
