import React from 'react'
import css from '../styles/AllProyect.module.scss';
import { ImGithub } from "react-icons/im";
import { MdOutlinePublic } from "react-icons/md";
import { FiFigma } from "react-icons/fi";
import { AiFillBehanceCircle } from "react-icons/ai"

function AllProyects({ id, img, link, name, tecnologies, repo, figma, behance, descripcion}) {
    return (
        <div className={css.proyects} key={id}>
            <h2><b>{name}</b></h2>
            <div className={css.links}>
                {link?.length > 0 ? <a href={link} target="_blank" rel="noreferrer"><MdOutlinePublic className={css.icon2} /></a> : ""}
                {repo?.length > 0 ? <a href={repo} target="_blank" rel="noreferrer"><ImGithub className={css.icon} /></a> : ""}
                {figma?.length > 0 ? <a href={figma} target="_blank" rel="noreferrer"><FiFigma className={css.icon} /></a> : ""}
                {behance?.length > 0 ? <a href={behance} target="_blank" rel="noreferrer"><AiFillBehanceCircle className={css.icon} /></a> : ""}
            </div>
             <a href={link?.length > 0 ? link : figma} target="_blank" rel="noreferrer">
                <img src={img} alt={name}/>
            </a>
            <h4>{descripcion}</h4>
            <h4>Tecnologías utilizadas: {tecnologies}</h4>
        </div>
    )
}

export default AllProyects;
