import React from 'react'
import css from '../styles/Navbar.module.scss';
import { DiApple } from "react-icons/di";

function Navbar() {
    const date = new Date();
    const hour = date.getHours() + ':' + date.getMinutes(); 

    return (
        <div className={css.nav}>
            <DiApple className={css.apple} />
            <div className={css.hour}>{hour}</div>
        </div>
    )
}

export default Navbar
