import React from 'react'
import css from '../styles/Skills.module.scss';
import javascript from "../assets/skills/front/javascript.svg";
import node from "../assets/skills/back/node-js.svg";
import express from '../assets/skills/back/express.svg';
import react from "../assets/skills/front/react.svg";
import angular from '../assets/skills/front/angular.svg';
import redux from "../assets/skills/front/redux.svg";
import html5 from "../assets/skills/front/html5.svg";
import css3 from '../assets/skills/front/css3.svg';
import sass from "../assets/skills/front/sass.svg";
import bootstrap from "../assets/skills/front/bootstrap.svg";
import figma from '../assets/skills/ux/figma.svg'
import mysql from "../assets/skills/bdd/mysql.svg";
import illustrator from "../assets/skills/ux/illustrator.svg";
import photoshop from "../assets/skills/ux/photoshop.svg";

function Skills() {
    return (
      <div>
        <h1>Tecnologías</h1>
        <div className={css.container}>
        <div className={css.skills}>
            <img src={figma} alt="Figma" />
            <h4>Figma</h4>
          </div>
          <div className={css.skills}>
            <img src={illustrator} alt="Illustrator" />
            <h4>Illustrator</h4>
          </div>
          <div className={css.skills}>
            <img src={photoshop} alt="Photoshop" />
            <h4>Photoshop</h4>
          </div>
          <div className={css.skills}>
            <img src={javascript} alt="Javascript" />
            <h4>Javascript</h4>
          </div>
          <div className={css.skills}>
            <img src={html5} alt="HTML5" />
            <h4>HTML5</h4>
          </div>
          <div className={css.skills}>
            <img src={css3} alt="CSS3" />
            <h4>CSS3</h4>
          </div>
          <div className={css.skills}>
            <img src={sass} alt="SASS" />
            <h4>SASS</h4>
          </div>
          <div className={css.skills}>
            <img src={bootstrap} alt="Bootstrap" />
            <h4>Bootstrap</h4>
          </div>
          <div className={css.skills}>
            <img src={angular} alt="Angular" />
            <h4>Angular</h4>
          </div>
          <div className={css.skills}>
            <img src={react} alt="React" />
            <h4>React</h4>
          </div>
          <div className={css.skills}>
            <img src={redux} alt="Redux" />
            <h4>Redux</h4>
          </div>
          <div className={css.skills}>
            <img src={node} alt="NodeJs" />
            <h4>NodeJs</h4>
          </div>
          <div className={css.skills}>
            <img src={mysql} alt="Mysql" />
            <h4>MySQL</h4>
          </div>
        </div>
      </div>
    );
}

export default Skills
