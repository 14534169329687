import React, { useEffect, useRef } from 'react'
import bitmoji from "../assets/images/bitmoji.png"
import css from '../styles/Presentation.module.scss';
import { init } from 'ityped'

function Presentation() {
    const textRef = useRef();

    useEffect(() => {
        init(textRef.current, {
            showCursor: false,
            backDelay: 1500,
            backSpeed: 50,
            strings: ["UX Designer"]
        })
    }, [])

    return (
        <div className={css.container}>
            <h2 className={css.intro}>Hola, soy Renata</h2>
            <div className={css.titleWrapper}>
                <span ref={textRef}></span>
            </div>
            <img src={bitmoji} className={css.photo} alt="bitmoji"/>
        </div>
    )
}

export default Presentation
