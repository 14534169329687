import React from 'react'
import css from '../styles/AboutMe.module.scss';

function AboutMe() {
    return (
        <div className={css.aboutMe}>
            <h1>Sobre mí</h1>
            <div className={css.container}>
                <h4>Soy Renata, tengo 25 años y vivo en Buenos Aires, Argentina.</h4>
                <h4>Siempre me apasionó el diseño, por lo cual empecé estudiando diseño de calzado y actualmente me encuentro cursando la Licenciatura de diseño de interiores en UADE.</h4>
                <h4>Mientras tanto fuí explorando otros caminos del diseño, lo que me llevó a estudiar y trabajar como Frontend developer para poder plasmar mis ideas en una página o aplicación a través de código.</h4>
                <h4>Al empezar a conocer sobre el mundo de la tecnología, me empezó a llamar la atención el diseño ux/ui.</h4>
                <h4>Por lo que empecé a prepararme y estudiar para así poder seguir como UX Designer.</h4>
                <h4>Actualmente pude lograr eso que tanto queria, ya que desde noviembre del 2022 hasta noviembre de 2023 trabajé como Diseñadora UX en IncluIT powered by Avenga.</h4>
                <h4></h4>
            </div>
        </div>
    )
}

export default AboutMe
