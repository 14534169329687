import React, { useEffect, useState } from 'react';
import css from '../styles/Home.module.scss';
import List from './List';
import Contact from './Contact';
import Proyects from './Proyects';
import Presentation from './Presentation';
import Skills from './Skills';
import AboutMe from './AboutMe';
import linkedin from '../assets/icons/linkedin.svg';
import behance from '../assets/icons/behance.svg';
import github from '../assets/icons/github.svg';

function Home() {

    const [selected, setSelected] = useState("new");
    const [data, setData] = useState([]);

    const list = [
        {
            id: "new",
            title: "Presentación"
        },
        {
            id: "aboutMe",
            title: "Sobre mí"
        },
        {
            id: "skills",
            title: "Tecnologías"
        },
        {
            id: "proyectos",
            title: "Proyectos"
        },
        {
            id: "contact",
            title: "Contacto"
        }
    ]

    useEffect(() => {
        switch (selected) {
            case "new":
                setData(<Presentation />)
                break;
            case "skills":
                setData(<Skills />)
                break;
            case "proyectos":
                setData(<Proyects />)
                break;
            case "contact":
                setData(<Contact />)
                break;
            default: setData(<Presentation />)
        }

    }, [selected])

    return (
        <div className={css.container}>
            <div className={css.notes}>
                <div className={css.browser}>
                    <div className={css.circle1}></div>
                    <div className={css.circle2}></div>
                    <div className={css.circle3}></div>
                </div>
                <ul className={css.left}>
                    {list.map(e => (
                        <List
                            title={e.title}
                            active={selected === e.id}
                            setSelected={setSelected}
                            id={e.id}
                            key={e.id}
                        />
                    ))}
                    <div className={css.info}>
                        <div className={css.imagen}>
                            <a href="https://www.linkedin.com/in/renataloustalet/" target="_blank" rel="noreferrer"><img src={linkedin} className={css.icon} alt='Linkedin' /></a>
                        </div>
                        <div className={css.imagen}>
                            <a href="https://www.behance.net/renataloustalet" target="_blank" rel="noreferrer"><img src={behance} className={css.icon} alt='Behance' /></a>
                        </div>
                        <div className={css.imagen}>
                            <a href="https://github.com/renataloustalet" target="_blank" rel="noreferrer"><img src={github} className={css.icon} alt='Github' /></a>
                        </div>
                    </div>
                </ul>
                <div className={css.right}>
                    <div className={css.leftWrapper}>
                        {selected === "new"
                            ? <Presentation />
                            : selected === "skills"
                                ? <Skills />
                                : selected === "proyectos"
                                    ? <Proyects />
                                    : selected === "contact"
                                        ? <Contact />
                                        : <AboutMe />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
